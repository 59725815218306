import React from 'react';
import PropTypes from 'prop-types';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

import { wrapper } from '@store/store';
import { selectIsUserLogged } from '@selectors/auth';
import { fetchHomepage } from '@thunks/homepage';

import Home from '@routes/Home/containers/Home';
import Error500 from '@routes/Error500';

import { fetchCMSComponentsData } from '@utils/fetchCMSComponentsData';
import request from '@utils/request';
import { getAccessToken } from '@utils/jwt';

import { cmsComponentsData } from '@common/types/cms';

const HomePage = ({ pageData, isError, componentsData }) =>
  isError ? (
    <Error500 />
  ) : (
    <Home pageData={pageData} componentsData={componentsData} />
  );

export const getServerSideProps = wrapper.getServerSideProps(
  store =>
    async ({ locale, req }) => {
      let isError = false;
      let pageData;
      let componentsData;

      try {
        const isUserLoggedIn = selectIsUserLogged(store.getState());
        const accessToken = getAccessToken(req);

        if (isUserLoggedIn && accessToken) {
          request.setAcceptLanguage(locale);
          request.setAuthorizationToken(accessToken);
        }

        pageData = await store.dispatch(fetchHomepage());

        componentsData = await fetchCMSComponentsData(store.dispatch, pageData);

        if (isUserLoggedIn) {
          request.removeAuthorizationToken();
        }

        componentsData = componentsData
          .filter(({ value }) => value)
          .map(({ value }) => value);
      } catch (e) {
        pageData = {};
        componentsData = {};
        isError = true;
      }

      return {
        props: {
          ...(await serverSideTranslations(locale)),
          pageData,
          componentsData,
          isError,
        },
      };
    },
);

HomePage.propTypes = {
  pageData: PropTypes.shape({
    body: PropTypes.arrayOf(PropTypes.shape({})),
    meta: PropTypes.shape({
      metaDescription: PropTypes.string,
      metaTitle: PropTypes.string,
    }),
  }).isRequired,
  isError: PropTypes.bool.isRequired,
  componentsData: cmsComponentsData.isRequired,
};

export default HomePage;
