import React from 'react';
import PropTypes from 'prop-types';

import FlipCard from 'app/common/components/FlipCard';
import { Cross } from '@common/components/Icons';

import {
  CardConditions,
  FlipCardCloseButton,
  FlipCardWrapper,
  ModalStyled,
} from './FlipCardModal.styled';

const FlipCardModal = ({
  bottomText,
  isOpen,
  onClose,
  cardRef,
  imageFront,
  imageBack,
}) => (
  <ModalStyled
    onClose={onClose}
    isOpen={isOpen}
    withCloseButton={false}
    timeout={1000}
    mountOnEnter
    unmountOnExit
    appear
    exit
    enter
  >
    <FlipCardWrapper>
      <FlipCardCloseButton onClick={onClose}>
        <Cross />
      </FlipCardCloseButton>
      <FlipCard
        imageBack={imageBack}
        imageFront={imageFront}
        isOpen={isOpen}
        onClose={onClose}
        cardRef={cardRef}
        bottomText={bottomText && <CardConditions>{bottomText}</CardConditions>}
      />
    </FlipCardWrapper>
  </ModalStyled>
);

FlipCardModal.propTypes = {
  bottomText: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  imageBack: PropTypes.node,
  imageFront: PropTypes.node,
  cardRef: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    current: PropTypes.any,
  }),
};

FlipCardModal.defaultProps = {
  bottomText: undefined,
  isOpen: false,
  cardRef: undefined,
  imageBack: undefined,
  imageFront: undefined,
};

export default FlipCardModal;
