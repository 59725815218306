import styled, { keyframes } from 'styled-components';

import { getThemeColor, getThemeTransition } from '@utils/styled';

import Typography from '@common/components/Typography';
import Modal from '@common/components/Modal';
import { PageWrapper } from '@common/containers/Layout/Layout.styled';
import Button, { BUTTON_VARIANTS } from '@common/components/Button';

export const BARCODE_WIDTH = 320;

const closeButtonFadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(10rem);
  }

  80% {
    opacity: 0;
    transform: translateY(10rem);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const FlipCardCloseButton = styled(Button).attrs({
  variant: BUTTON_VARIANTS.LEVEL_2_BLUEGREY_90_SMALL,
})`
  align-self: flex-end;
  width: auto;
  height: auto;
  border-radius: ${({ theme }) => theme.borderRadiuses.circle};
  ${({ theme }) => theme.getFluidSpacing('margin-bottom', 'equal', 5)};
  ${({ theme }) => theme.getFluidSpacing('padding', 'equal', 5)};
  transform: translateY(10rem);
  animation-fill-mode: forwards;

  svg {
    transform: scale(1.5);
  }

  &:hover,
  &:active,
  &:focus {
    border-color: transparent;
    background-color: rgba(0, 0, 0, 0.1);
    color: ${getThemeColor('grey.100')};
  }

  .modal-transition-enter-active &,
  .modal-transition-enter-done & {
    animation: ${closeButtonFadeIn} forwards
      ${getThemeTransition('', 'verySlow', 'default')};
    animation-fill-mode: forwards;
  }

  .modal-transition-exit-active &,
  .modal-transition-exit-done & {
    animation: ${closeButtonFadeIn} reverse
      ${getThemeTransition('', 'verySlow', 'default')};
    animation-fill-mode: forwards;
  }
`;

export const CardConditions = styled(Typography).attrs({
  customAs: 'span',
  variant: 'paragraph1',
})`
  ${({ theme }) => theme.getFluidSpacing('margin-top', 'equal', 3)};
  margin-bottom: 0;
  color: ${getThemeColor('grey.100')};
`;

export const ModalStyled = styled(Modal)`
  ${PageWrapper} {
    overflow-y: scroll;
    align-items: center;
    min-height: 100%;
    max-height: 100%;
  }
`;

export const FlipCardWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: ${BARCODE_WIDTH}px;
  ${({ theme }) => theme.getFluidSpacing('padding-top', 'scale', 3)};
  ${({ theme }) => theme.getFluidSpacing('padding-bottom', 'scale', 3)};
`;
