import React, { Fragment, useState } from 'react';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { selectIsUserLogged } from '@selectors/auth';

import { getRouteUrl } from '@utils/urls';

import FlipCardModal from 'app/common/components/FlipCardModal';
import { StaticCard } from 'app/common/components/CardBack/StaticCard';
import { cmsComponentsData } from '@common/types/cms';
import Layout from '@common/containers/Layout';
import CMSComponentsRenderer from '@common/components/CMSComponents/CMSComponentsRenderer';
import { IconButton } from '@common/components/IconButton';
import * as Icons from '@common/components/Icons';
import { HOME } from '@common/routes';
import DeepLinks from '@common/components/DeepLinks';
import { VIP_CARD } from '@common/images';
import { VIPCardBack } from '@common/components/CardBack/VipCardBack';

import { HomeRouteLayout, VIPControlsWrapper } from './Home.styled';

const Home = ({ pageData: { body, meta }, componentsData }) => {
  const { locale } = useRouter();
  const [isVIPCardModalOpen, setIsVIPCardModalOpen] = useState(false);
  const isLoggedIn = useSelector(selectIsUserLogged);
  const pageUrl = getRouteUrl(locale, HOME);

  return (
    <Fragment>
      <DeepLinks />
      <Layout
        title={meta?.metaTitle}
        description={meta?.metaDescription}
        canonicalPageUrl={pageUrl}
      >
        <HomeRouteLayout>
          {isLoggedIn && (
            <React.Fragment>
              <FlipCardModal
                onClose={() => {
                  setIsVIPCardModalOpen(false);
                }}
                isOpen={isVIPCardModalOpen}
                imageFront={
                  <StaticCard
                    image={{
                      src: VIP_CARD,
                      alt: 'VIP-Karte',
                    }}
                  />
                }
                imageBack={<VIPCardBack />}
              />
              <VIPControlsWrapper>
                <IconButton
                  type="button"
                  icon={<Icons.VIPCard />}
                  onClick={() => {
                    setIsVIPCardModalOpen(true);
                  }}
                />
              </VIPControlsWrapper>
            </React.Fragment>
          )}
          <CMSComponentsRenderer
            firstAsHero
            body={body}
            componentsData={componentsData}
          />
        </HomeRouteLayout>
      </Layout>
    </Fragment>
  );
};

Home.propTypes = {
  pageData: PropTypes.shape({
    body: PropTypes.arrayOf(PropTypes.shape({})),
    meta: PropTypes.shape({
      metaDescription: PropTypes.string,
      metaTitle: PropTypes.string,
    }),
  }).isRequired,
  componentsData: cmsComponentsData.isRequired,
};

export default Home;
