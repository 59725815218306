import styled from 'styled-components';

import { media } from '@utils/styled';

import RouteLayout from '@common/containers/RouteLayout';

export const HomeRouteLayout = styled(RouteLayout).attrs({
  withoutStickyBar: true,
})`
  ${media.from880down`
    margin-top: 0;
  `}
`;

export const VIPControlsWrapper = styled.div`
  position: absolute;
  ${({ theme }) => theme.getFluidSpacing('top', 'scale', 7)};
  right: 0;
  z-index: 10;

  ${media.from880up`
    display: none;
  `}
`;
